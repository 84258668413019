 import '../scss/base.scss';
 import $ from 'jquery';

 // JQUERY
 var jquery = require("jquery");
  window.$ = window.jQuery = jquery; // notice the definition of global variables here
  require("jquery-ui-dist/jquery-ui.js");
  (function($) {

  // * * * VARIABLES * * * 

  // * * * 1 - HEADER SECTION
  const laptop = $("#macbook");
  const slogan = $("#slogan");
  const triangelTop = $(".triangel");
  const triangelTopClassic = document.getElementById('triangel');
  const triangelDownClassic = document.getElementById('triangel2');

  // * * * 2 - SERVICE SECTION
  const serv1 =  $('.services-1');
  const serv2 =  $('.services-2');
  const serv3 =  $('.services-3');
  const servLi1 =  $('.services-li-1');
  const servLi2 =  $('.services-li-2');
  const servLi3 =  $('.services-li-3');

  // * * * 3 - DELIVERY
  const server = document.getElementById('serverPic'); 
  const delivery = $('#delivery');
  const deliveryTitle = $('#deliveryTitle');
  const deliveryButton = $('#deliveryButton');
  const deliveryHardware = $('.svg-hardware');

  // * * * 4 - REMOTE 
  const imgOutsource = $('.img-outsource');

  // * * * 5 - REFERECES
  const references = $('#references');
  const referencesTitle = $('#referencesTitle');
  const referencesButton = $('#referencesButton');
  const refRightLi = $('.ref-right-li');
  // SHAPES
  const shapeTop = $('.shape-svg');
  const shapeDown = $('.shape-svg2');

   // * * * 6 - SOFTWARE
   const software = $('#software');
   const softwareTitle = $('#softwareTitle');
   const softwareButton = $('#softwareButton');
   const soft1 = $('#soft1');
   const soft2 = $('#soft2');
   const soft3 = $('#soft3');

  
// * * * * GET OFFSET FUNCTION * * * *  
  let offset;

$(window).on('scroll', function() {
  function getOffset() {
       offset = window.pageYOffset;
}
    getOffset(offset);
})
// * * * * END FUNC * * * *  






// ON LOAD EVENT * * * * * 
$(window).on('load', function() {
 
  slogan.addClass('main-slogan-visible');

   setTimeout(() => {
    triangelTop.addClass('triangel-move');

    laptop.addClass('translateXfromRight');
    laptop.addClass('opacityOne');
  }, 300);  

   setTimeout(() => {
    triangelTop.addClass('transitionNone');
    }, 1500);
});



// * * * * * * SCROLL EVENT * * * * * * * * * * * * SCROLL EVENT * * * * * *  * * * * * * SCROLL EVENT * * * * * * 
// * * * * * * SCROLL EVENT * * * * * * * * * * * * SCROLL EVENT * * * * * *  * * * * * * SCROLL EVENT * * * * * * 
// * * * * * * SCROLL EVENT * * * * * * * * * * * * SCROLL EVENT * * * * * *  * * * * * * SCROLL EVENT * * * * * * 
window.addEventListener('scroll', function() {
//console.log(offset);

// * * * 1 - HEADER SECTION

  // server parallax
  server.style.backgroundPositionY = (offset - 1300) * 0.3 + "px";
  //triangel move 
  triangelTopClassic.style.transform = `scaleY(${ (1 - offset / 700)})`;
  triangelDownClassic.style.transform = `scaleY(${ ( (offset - 500) / 1000)})`;


// * * * 2 - SERVICE SECTION
  if (offset > 200) {
    
    // move up 
    setTimeout(() => {
      servLi1.addClass('services-li-move');
    },300)
    setTimeout(() => {
      servLi2.addClass('services-li-move');
    },600)
    setTimeout(() => {
      servLi3.addClass('services-li-move');
    },900)
    
    // fade in
    serv1.delay().fadeIn(1000);
    serv2.delay(350).fadeIn(1000);
    serv3.delay(700).fadeIn(1000);
  }

  // * * * 3 - DELIVERY
  if (offset > 600) {
    delivery.addClass('translateXfromLeft');
    delivery.addClass('opacityOne');

    setTimeout(() => {
    deliveryTitle.addClass('translateXfromLeft');
    deliveryTitle.addClass('opacityOne');
  },150)

  setTimeout(() => {
    deliveryButton.addClass('translateXfromLeft');
    deliveryButton.addClass('opacityOne');
  },350)

 
  setTimeout(() => {
    deliveryHardware.addClass('translateYtoUp');
    deliveryHardware.addClass('opacityOne');
  },250)


    //$(window).off("scroll");
  }

  if (offset > 1300) {
  imgOutsource.addClass('translateYtoUp');
    setTimeout(() => {
      imgOutsource.addClass('opacityOne');
    },150)
  }

  // * * * 5 - REFERECES
  if (offset > 1600) {
    setTimeout(() => {
      references.addClass('translateXfromLeft');
      references.addClass('opacityOne');
  
      setTimeout(() => {
      referencesTitle.addClass('translateXfromLeft');
      referencesTitle.addClass('opacityOne');
    },150)
  
    setTimeout(() => {
      referencesButton.addClass('translateXfromLeft');
      referencesButton.addClass('opacityOne');
    },350)

      refRightLi.addClass('opacityOne');
      refRightLi.addClass('translateYtoUp');
    },600)
  }

  // * * * 5 - REFERECES - SHAPES
  if (offset > 1700) {
    shapeTop.addClass('shape-scale-one');
    shapeDown.addClass('shape-scale-one');
    //$(window).off("scroll");
  }


  // SOFTWARE
  if (offset > 2250) {
    setTimeout(() => {
      software.addClass('translateXfromLeft');
      software.addClass('opacityOne');
  
      setTimeout(() => {
      softwareTitle.addClass('translateXfromLeft');
      softwareTitle.addClass('opacityOne');
      soft2.addClass('opacityOne');
    },350)
  
    setTimeout(() => {
      softwareButton.addClass('translateXfromLeft');
      softwareButton.addClass('opacityOne');
    },550)

    setTimeout(() => {
      soft1.addClass('opacityOne');
      soft3.addClass('opacityOne');

      soft1.addClass('translateXfromLeft');
      soft3.addClass('translateXfromLeft');
    },550)

    },600)
  }


});

// * * * * FOOTER * * * * 

// author
const author = $('.authorWrap');
const authorName = 'Rainsmarts';

author.append(`<span>${authorName}</span>`);

// date
const date = new Date();
const year = date.getFullYear();
const yearSpan = $('.year');

yearSpan.append(`© ${year}`);

// MOBILE MENU CLOSE BUTTON
const mobileMenu = $('.mobile-menu');
const liElements = $('.li_mob');

// IF CLICK ON LINK, MENU HIDE
liElements.on('click', function() {
  $('#nav-icon1').toggleClass('open');
  mobileMenu.toggleClass('mobile-menu-active');
});

// X
$(document).ready(function(){
	$('#nav-icon1').click(function(){
		$(this).toggleClass('open');
    mobileMenu.toggleClass('mobile-menu-active');
	});
});

// ON RESIZE MENU HIDE 
window.addEventListener('resize', function(event) {

  if (window.innerWidth > 920) {
    $('#nav-icon1').removeClass('open');
     mobileMenu.removeClass('mobile-menu-active');
  }
}, true);


})(jQuery); 




